import "./index.scss";
import React from "react";
import { Avatar, Row, Col, Button, Image } from "antd";
import JuniorSelectionVoteButtonBlankPng from "../../assets/images/junior_selection_vote_button_blank.png";
import JuniorSelectionVoteButtonVotedPng from "../../assets/images/junior_selection_vote_button_voted.png";
import { useNavigate } from "react-router-dom";
import { getToken, removeToken } from "../../utils";
import { useStore } from "../../store";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
function FundraiserHomeComponent({ props }) {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { fundraiserStore,homeStore } = useStore();
  const [vote, setVote] = useState(); // 每一个campaign的vote量，用来不刷新界面，自动增长

  useEffect(() => {
    let obj = {};
    props.map((prop) => {
      prop.map((item) => {
        obj[item.id] = item.voteAmount || 0;
      });
    });
    setVote(obj);
  }, [props]);

  // 添加投票
  const postVoteRecord = async (campaignId) => {
    let token = getToken(campaignId);
    let obj = { ...vote };

    if (!token) {
      await fundraiserStore.createVoteRecord({ campaignId });
      obj[campaignId] = obj[campaignId] + 1;
    } else {
      await fundraiserStore.deleteVoteRecord({ id: token, campaignId });
      obj[campaignId] = obj[campaignId] - 1;
    }

    setVote({
      ...obj,
    });
  };

  return (
    <div className="fundraiser-home-component">
      {props.map((prop, index) => {
        return (
          <Row
            key={index}
            gutter={[16, 16]}
            style={{ marginTop: 24, marginBottom: 24 }}
          >
            {prop.map((item, index2) => {
              return (
                <React.Fragment key={index2}>
                    <Col xs={7} sm={5}>
                        <div>
                            <Avatar
                                className="component-img"
                                size={{
                                    xs: 100,
                                    sm: 120,
                                    md: 140,
                                    lg: 160,
                                    xl: 180,
                                    xxl: 200,
                                }}
                                src={item.avatarSrc}
                            ></Avatar>
                        </div>
                    </Col>

                  <Col
                    xs={17}
                    sm={7}
                  >
                    <div
                      className={[
                        "component-detail ",
                        item.type !== "SCHOOL"
                          ? "component-detail-corporate"
                          : null,

                      ].join(" ")}
                    >
                      <h4 className="detail-name">
                        {item.name[i18n.language.replace("_", "-")]}
                      </h4>
                      {item.raised !== undefined ? (
                        <h4 className="detail-raised">
                          {t("raisedText")} HK${item.raised}
                        </h4>
                      ) : null}
                      {/* {item.organizer !== undefined ? (
                        <h5 className="detail-organizer">
                          {t("organizerText")}: {item.organizer}
                        </h5>
                      ) : null} */}

                      {item.type === "SCHOOL" && homeStore.systemSetting?.is_vote_enabled ? (
                        <div className="detail-favorite">
                          <Image
                            src={
                              getToken(item.id)
                                ? JuniorSelectionVoteButtonVotedPng
                                : JuniorSelectionVoteButtonBlankPng
                            }
                            width={40}
                            onClick={() => postVoteRecord(item.id)}
                            preview={false}
                            style={{
                              cursor: "pointer",
                            }}
                          ></Image>
                          <h4 className="favorite-amount">
                            &nbsp;{vote ? vote[item.id] : null}
                          </h4>
                        </div>
                      ):null}

                      <div className="detail-button">
                        <Button
                          type="primary"
                          shape="round"
                          danger
                          className="learn-more-button"
                          onClick={(e) => {
                            e.preventDefault();
                            navigate("/campaign/" + item.id);
                          }}
                          style={{}}
                          href={"/campaign/" + item.id}
                        >
                          {t("btnLearnMore")}
                        </Button>
                      </div>
                    </div>
                  </Col>
                </React.Fragment>
              );
            })}
          </Row>
        );
      })}
    </div>
  );
}
export default FundraiserHomeComponent;
